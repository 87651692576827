import React from "react";
import Layout from "../../components/layout";
import Requests from "../../components/requests";

const Index = () => {
  return (
    <div>
      <Layout inline={true}>
        <Requests />
      </Layout>
    </div>
  );
};

export default Index;
